.payment-methods {
  margin-bottom: $spacer;
  background: darken($body-bg, 4%);
  padding: calc($spacer / 3);
  border-radius: 0.25rem;
  display: flex;
  justify-content: flex-start;
  align-content: space-evenly;
  align-items: center;
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }

  &-item {
    min-width: 150px;
    width: 50%;
    color: $text-muted;
    text-align: center;
    padding: calc($spacer / 3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    user-select: none;
    border-radius: 0.25rem;
    border: 1px solid transparent;

    > figure {
      height: 32px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;

      > img {
        max-height: 32px;
        max-width: 120px;
        display: block;
        object-fit: contain;
        filter: grayscale(1);
        opacity: 0.3;
      }
    }

    &.active {
      color: theme-color('primary');
      background: white;

      > figure {
        > img {
          filter: none;
          opacity: 1;
        }
      }
    }

    &:hover {
      color: theme-color('primary');
      text-decoration: none;
    }
  }
}

.btn-view-templates {
  position: absolute;
  top: 0;
  right: 1.5rem;
  height: 63px;
  display: flex;
  align-items: center;
}
