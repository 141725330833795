.prices {
  margin-bottom: $spacer;
  background: white;
  border-radius: 0.25rem;

  &-silver {
    background: lighten(#bcc4d4, 8%);
  }

  &-gold {
    background: lighten(theme-color('warning'), 18%);
  }
}
